<template>
  <div :class="`popup__horizontal-${$popup.horizontal} popup__vertical-${$popup.vertical}`"
       class="popup"
       v-on:click.self="close">
    <div class="popup__wrapper">
      <div :class="classList"
           :style="style"
           class="popup__content">
        <component :is="$popup.type"/>
        <div class="popup__close"
             v-on:click="close">
          <Icon viewport="0 0 24 24"
                xlink="close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  methods: {
    close() {
      this.$store.commit('CLOSE_POPUP');
    },
    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    },
  },
  computed: {
    classList() {
      let list = [];
      if (this.$popup.horizontal) list = [...list, `popup__content-${this.$popup.horizontal}`];
      return list;
    },
    style() {
      return {
        width: this.isNumber(this.$popup.width) ? `${this.$popup.width}px` : this.$popup.width,
        height: this.isNumber(this.$popup.height) ? `${this.$popup.height}px` : this.$popup.height,
      };
    },
  },
};
</script>
